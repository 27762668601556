import create from '@workable/shield-spa-sdk';
import memoize from 'memoizee';
import qs from 'query-string';
import config, {meta} from 'candidate/config';
import rollbar from 'candidate/rollbar';
import {account} from 'candidate/shared/paths';

export const shieldConfig = (originalConfig, _meta = meta, _window = window) => {
  return _meta.hasCustomDomain
    ? {
        ...originalConfig,
        redirectUri: `${_window.location.origin}${new URL(originalConfig.redirectUri).pathname}`
      }
    : originalConfig;
};

const _shield = config.then(({shield: {basic}}) => create(shieldConfig(basic)));

const memoized = memoize(
  s => s.getTokenSilently({accountSubdomain: (meta.hasCustomDomain && meta.subdomain) || account()}),
  {
    length: 1,
    maxAge: 2 * 60 * 1000, // 2 minutes
    promise: true
  }
);

const redirectToReferrals = async () => {
  window.location.href = new URL(
    `${meta.subdomain}/jobs/${meta.job}`,
    `https://referrals.${meta.domain ?? 'officedroid.com'}`
  );
};

export const processShieldResponse = (request, {error, id_token} = {}) => {
  if (error) {
    rollbar?.error?.(error);

    if (error?.data?.error === 'login_required' || error?.data?.error === 'interaction_required') {
      redirectToReferrals();
    }
  } else if (id_token) {
    request.headers.Authorization = `Bearer ${id_token}`;
  }
};

export const authInterceptor = request =>
  _shield
    .then(memoized)
    .then(token => processShieldResponse(request, token))
    .catch(e => rollbar && rollbar.error && rollbar.error(e))
    .then(() => request);

export const withAuth = search => {
  const query = qs.parse(search || '');
  const isInternal = query && query.utm_source === 'internal_application';
  return http => {
    if (isInternal) {
      http.careers.interceptors.request.use(authInterceptor);
      http.form.interceptors.request.use(authInterceptor);
    }
    return http;
  };
};

export default withAuth(window.location.search);
