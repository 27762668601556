import React, {useState, useRef, useCallback} from 'react';

import KeyboardHandler from 'shared/ui/behaviors/keyboardHandler';
import SecondaryHeading from 'shared/ui/atoms/heading/secondary';
import MainBody from 'shared/ui/atoms/text/mainBody';
import TextSecondary from 'shared/ui/atoms/text/secondary';
import Toggle from 'shared/ui/molecules/control/switch/toggle';
import CheckCircle from 'shared/ui/atoms/icon/checkCircle';
import RemoveCircle from 'shared/ui/atoms/icon/removeCircle';

import {Translations} from '../../../types';

import styles from './styles.scss';

type CookieTab = {
  id: string;
  title: string;
  description: string | JSX.Element;
  index: number;
  preEnabled?: boolean;
};

export type CookieTabsProps = React.HTMLAttributes<HTMLDivElement> & {
  texts: Translations;
  tabs: CookieTab[];
  selections: boolean[];
  onToggle: (index: number, value: boolean) => void;
};

const CookieTabs = ({texts, tabs, selections, onToggle, ...props}: CookieTabsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>, section: CookieTab) => {
    if (section.preEnabled) {
      return;
    }

    onToggle(section.index, event.target.checked);
  };

  const updateActiveTab = (id: string) => {
    setActiveTab(id);

    if (containerRef.current) {
      const tab = containerRef.current.querySelector<HTMLDivElement>(`#${id}`);
      tab?.focus();
    }
  };

  const handleArrowsPressed = useCallback(
    (_, params?: {direction: 'up' | 'down' | 'left' | 'right'}) => {
      if (!params) {
        return;
      }

      const isSelectingNext = params.direction === 'down';
      const currentIndex = tabs.findIndex(tab => tab.id === activeTab);
      const nextIndex = currentIndex + (isSelectingNext ? 1 : -1);
      const nextTab = tabs[nextIndex];

      if (nextTab) {
        updateActiveTab(nextTab.id);
        return;
      }

      const firstTab = tabs[0];
      const lastTab = tabs[tabs.length - 1];

      if (isSelectingNext) {
        updateActiveTab(firstTab.id);
      } else {
        updateActiveTab(lastTab.id);
      }
    },
    [activeTab, tabs]
  );

  return (
    <KeyboardHandler handleArrowsPressed={handleArrowsPressed}>
      <div ref={containerRef} {...props}>
        {tabs.map(section => {
          const sectionId = section.id;
          const panelId = `${section.id}_description`;

          const isTabActive = section.id === activeTab;
          const isTogglable = section.index >= 0;
          const isEnabled = section.preEnabled || !!selections[section.index];

          return (
            <div
              key={section.id}
              role="tab"
              aria-controls={panelId}
              aria-selected={isTabActive}
              className={styles.section}
            >
              <div
                id={sectionId}
                aria-label={section.title}
                className={styles.tab}
                tabIndex={isTabActive ? 0 : -1}
                onClick={() => updateActiveTab(section.id)}
              >
                <MainBody>{section.title}</MainBody>
                {isEnabled && <CheckCircle success />}
                {isTogglable && !isEnabled && <RemoveCircle danger />}
              </div>

              <div
                id={panelId}
                role="tabpanel"
                aria-labelledby={sectionId}
                aria-hidden={!isTabActive}
                className={styles.panel}
              >
                <div className={styles.panelHeader}>
                  <SecondaryHeading>{section.title}</SecondaryHeading>

                  {(section.preEnabled || isTogglable) && (
                    <div className={styles.toggle}>
                      {section.preEnabled && <TextSecondary color="green500">{texts.alwaysActive}</TextSecondary>}
                      <Toggle
                        checked={isEnabled}
                        disabled={section.preEnabled}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggle(e, section)}
                      />
                    </div>
                  )}
                </div>
                <MainBody>{section.description}</MainBody>
              </div>
            </div>
          );
        })}
      </div>
    </KeyboardHandler>
  );
};

export default CookieTabs;
