import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import NakedToggle from '../naked';
import BodyText from 'shared/ui/atoms/text/body';
import getRandomString from 'shared/ui/helpers/getRandomString';
import styles from './styles.scss';

const Toggle = ({
  id = getRandomString(),
  disabled,
  reverse,
  spaceBetween,
  children,
  className,
  labelWrap,
  emphasis,
  title,
  ...props
}) => {
  const textProps = {
    id: `checkbox_label_${id}`
  };

  const WrapperComponent = labelWrap ? 'label' : 'div';

  return (
    (<WrapperComponent
      title={title}
      className={clsx({
        [styles.wrapper]: true,
        [styles.disabled]: disabled,
        [styles.reverse]: reverse,
        [styles.emphasis]: emphasis,
        [styles["space-between"]]: spaceBetween
      }, className)}>
      <NakedToggle {...props} {...{disabled}} labelWrap={false} aria-labelledby={textProps.id} />
      <BodyText {...textProps} className={clsx(styles.text, textProps.className)}>
        {children}
      </BodyText>
    </WrapperComponent>)
  );
};

Toggle.defaultProps = {
  labelWrap: true,
  onChange: () => undefined
};

Toggle.propTypes = {
  ...NakedToggle.propTypes,
  /** The text which will be rendered as the label of the input. */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Wraps the input in a label. Be default: `true`. */
  labelWrap: PropTypes.bool,
  /** Reverses toggle and text. Makes toggle sit to the right of text. */
  reverse: PropTypes.bool,
  /** Makes text and toggle to sit on the edges (To have empty space between them.) */
  spaceBetween: PropTypes.bool
};

export default Toggle;
