import {useState, useCallback} from 'react';

import {getCatPrefCookieSelections} from 'shared/ui/organisms/cookieConsent/helpers';

import {Selections} from '../..';

const useSettings = () => {
  const [selections, setSelections] = useState<Selections>(() => getCatPrefCookieSelections());

  const updateSelection = useCallback((index: number, value: boolean) => {
    setSelections(prev => {
      const newSelections: Selections = [...prev];

      newSelections[index] = value;

      return newSelections;
    });
  }, []);

  return {
    selections,
    updateSelection
  };
};

export default useSettings;
