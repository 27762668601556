import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import LabeledRadio from 'shared/ui/atoms/input/radio/labeled';
import SubHeading from 'shared/ui/atoms/text/subheading';
import Text from 'shared/ui/atoms/text/body';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';
import withMemo from 'shared/ui/helpers/hocs/withMemo';
import symbols from 'shared/ui/symbols';
import isProd from '../../../../../../config/webpack/environment/isProd';
import styles from './styles.scss';

const accessibleNameErrorMessage =
  'If radio has no text that is visible to screen readers, it should have `aria-label` or `aria-labelledby` attribute to obtain discernible text.';
const DEFAULT_ICON_SIZE = 48;
const isProduction = isProd();

const RichRadio = ({message, children, disabled, responsive, ...props}) => {
  const isChecked = props.checked !== undefined ? props.checked : props.defaultChecked;
  const richStyles = mapPropsToStyleNames([{default: 'rich-radio'}, {responsive, disabled, checked: isChecked}]);

  const body = [];
  let illustration;
  React.Children.forEach(children, child => {
    if (!child) {
      return;
    }

    if (child.type && child.type[symbols.Icon]) {
      const {type: Icon, props: childProps} = child;
      const {right, ...restProps} = childProps;

      illustration = (
        <div
          className={clsx({[styles.illustration]: true, [styles.right]: right === true})}
          data-role="rich-radio-illustration"
        >
          <Icon size={DEFAULT_ICON_SIZE} {...restProps} />
        </div>
      );

      return;
    }

    return body.push(child);
  });

  const accessibleNameIsMissing = !message && !body.length && !props['aria-label'] && !props['aria-labelledby'];

  if (!isProduction && accessibleNameIsMissing) {
    console.error(accessibleNameErrorMessage); // eslint-disable-line no-console
  }

  const textContent =
    message || body.length ? (
      <div className={styles.content} data-role="rich-radio-content">
        {message ? (
          <SubHeading emphasis strong>
            {message}
          </SubHeading>
        ) : null}
        {body.length ? <Text neutral>{body}</Text> : null}
      </div>
    ) : null;

  return (
    <LabeledRadio
      {...props}
      disabled={disabled}
      data-role="rich-radio"
      data-checked={isChecked}
      className={clsx(
        richStyles
          .split(' ')
          .map(name => styles[name])
          .join(' '),
        props.className
      )}
    >
      {illustration}
      {textContent}
    </LabeledRadio>
  );
};

const MemoRichRadio = withMemo(RichRadio);

MemoRichRadio.displayName = 'RichRadio';

MemoRichRadio[symbols.Input.Radio.Rich] = true;

MemoRichRadio.propTypes = {
  message: PropTypes.string
};

export default MemoRichRadio;
