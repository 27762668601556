export const getEnvironmentFromHostname = (hostname = window.location.hostname) => {
  const hostParts = hostname.split('.');

  const domain = hostParts[hostParts.length > 1 ? hostParts.length - 2 : hostParts.length - 1].toLowerCase();

  const environment = hostname.endsWith('workable.com')
    ? 'production'
    : hostname.endsWith('workable.dev') || hostname.endsWith('localhost') || hostname.endsWith('lvh.me')
      ? 'development'
      : domain;

  return environment;
};
