import React, {useCallback} from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import KeyboardHandlers from 'shared/ui/behaviors/keyboardHandler';
import NativeCheckbox from 'shared/ui/atoms/input/checkbox/native';
import propsFilter from 'shared/ui/helpers/propsFilter';
import styles from './styles.scss';

const clickTheCheckbox = e => {
  if (e && typeof e.preventDefault === 'function') {
    e.preventDefault();
  }

  if (e && e.currentTarget && typeof e.currentTarget.querySelector === 'function') {
    e.currentTarget.querySelector('input').click();
  }
};

const NakedToggle = ({labelWrap, toggleOnClick, ...props}) => {
  const {checked, disabled, required} = props;
  const WrapperComponent = labelWrap ? 'label' : 'div';

  const composePropsFilter = propsFilter(props).ariaAttributes().dataAttributes().styles();
  const allowedProps = composePropsFilter.getFiltered();
  const restProps = composePropsFilter.excludeFiltered();

  const handleClick = useCallback(
    e => {
      if (toggleOnClick) {
        clickTheCheckbox(e);
      }
    },
    [toggleOnClick]
  );

  return (
    (<KeyboardHandlers handleSpacePressed={clickTheCheckbox}>
      <WrapperComponent
        {...allowedProps}
        className={clsx(styles.toggle, allowedProps.className)}
        tabIndex="0"
        role="switch"
        aria-checked={checked}
        aria-disabled={disabled}
        aria-required={required}
        onClick={handleClick}
      >
        <NativeCheckbox tabIndex="-1" aria-hidden {...restProps} />
      </WrapperComponent>
    </KeyboardHandlers>)
  );
};

NakedToggle.defaultProps = {
  onChange: () => undefined
};

NakedToggle.propTypes = {
  ...NativeCheckbox.propTypes,
  /** Wraps the input in a label. Be default: `true`. */
  labelWrap: PropTypes.bool
};

export default NakedToggle;
