import {CAT_PREF_COOKIE_NAME, DO_NOT_OPT_OUT} from 'shared/ui/organisms/cookieConsent/constants';
import {getOptOutCookieValue} from 'shared/ui/organisms/cookieConsent/helpers';

const isPreferenceEnabled = preferenceByCategory => preferenceByCategory?.split(':')?.[1] === 'true';

export const getCookieCategoriesPreferences = () => {
  const matchCookieNameRegex = new RegExp('(^|;)\\s*' + CAT_PREF_COOKIE_NAME + '\\s*=\\s*([^;]+)');

  const preferences = (matchCookieNameRegex.exec(document.cookie)?.pop() ?? '').split(',');

  return {
    areAdvertisementCookiesEnabled: isPreferenceEnabled(preferences[1]),
    areAnalyticsCookiesEnabled: isPreferenceEnabled(preferences[0]),
    areFunctionalityCookiesEnabled: isPreferenceEnabled(preferences[2])
  };
};

export const watchForConsent = () =>
  new Promise(res => {
    const interval = setInterval(() => {
      const optOutCookieValue = getOptOutCookieValue();
      const isConsentPresent = optOutCookieValue !== '';
      const hasGivenConsent = optOutCookieValue === DO_NOT_OPT_OUT;

      if (isConsentPresent && !hasGivenConsent) {
        clearInterval(interval);

        res({
          areAdvertisementCookiesEnabled: false,
          areAnalyticsCookiesEnabled: false,
          areFunctionalityCookiesEnabled: false,
          hasGivenConsent: false
        });

        return;
      }

      if (hasGivenConsent) {
        clearInterval(interval);

        res({
          hasGivenConsent: true,
          ...getCookieCategoriesPreferences()
        });
      }
    }, 1000);
  });
