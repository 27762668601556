import React from 'react';
import clsx from 'clsx';

import PropTypes from 'prop-types';
import symbols from 'shared/ui/symbols';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';

import IllustratedContainer from 'shared/ui/atoms/icon/container';

import styles from './styles.scss';

/**
 * @class Group
 * @extends {React.Component}
 *
 * Renders a group.
 */
const Group = ({
  as: Kind = 'div',
  children,
  label,
  rounded = true,
  role = 'group',
  stacked,
  error,
  warning,
  responsive,
  ...props
}) => {
  const styleNames = mapPropsToStyleNames([
    {group: true},
    {vertical: stacked, horizontal: !stacked, default: 'horizontal', responsive, rounded}
  ]);

  // Checks if the group contains children that are not IconButton, to avoid conflict with IconButtonGroup
  const containsNonIconButtonChildren = React.Children.toArray(children).some(
    child => !(child.type && child.type[symbols.Button.Icon])
  );

  const renderChildren = () => {
    return React.Children.toArray(children)
      .filter(Boolean)
      .map((child, index, filteredChildren) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        const isIconButton = child.type && child.type[symbols.Button.Icon];
        const isIcon = child.type && child.type[symbols.Icon];

        const isFirstItem = index === 0;
        const isLastItem = index === filteredChildren.length - 1;

        const ariaAttributes = {
          'aria-labelledby': props['aria-labelledby']
        };

        const dataAttributes = {
          'data-warning': child.props.warning,
          'data-error': child.props.error,
          'data-readonly': child.props.readOnly
        };

        if (!(containsNonIconButtonChildren && isIconButton) && !isIcon) {
          return (
            <child.type
              error={error}
              warning={warning}
              key={child.key}
              {...child.props}
              className={clsx(
                {
                  [styles['group-item']]: true,
                  [styles['first-item']]: isFirstItem && rounded,
                  [styles['last-item']]: isLastItem && rounded
                },
                child.props.className
              )}
              {...ariaAttributes}
              {...dataAttributes}
            />
          );
        }

        return (
          <IllustratedContainer
            className={clsx({
              [styles['illustrated-group-item']]: true,
              [styles['group-item']]: true,
              [styles['first-item']]: isFirstItem && rounded,
              [styles['last-item']]: isLastItem && rounded,
              [styles.error]: error,
              [styles.warning]: warning
            })}
            key={child.key}
          >
            {isIconButton
              ? React.cloneElement(child, {
                  transparent: child.props.transparent !== undefined ? child.props.transparent : true
                })
              : child}
          </IllustratedContainer>
        );
      });
  };

  return (
    <Kind
      role={role}
      aria-label={label}
      {...props}
      className={clsx(
        styleNames
          .split(' ')
          .map(c => styles[c])
          .join(' '),
        props.className
      )}
    >
      {renderChildren()}
    </Kind>
  );
};

Group.propTypes = {
  /** The element accessible role */
  role: PropTypes.string,
  /** Aligns grouped items vertically if stacked. Horizontally otherwise */
  stacked: PropTypes.bool,
  /** Sets the related group aria label to the value passed */
  label: PropTypes.string,
  /** Morphs group into another Component. Defaults to div */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** If true, applies error styles to children */
  error: PropTypes.bool,
  /** If true, applies warning styles to children */
  warning: PropTypes.bool,
  /** If true, applies responsive styles to all inputs */
  responsive: PropTypes.bool
};

Group.displayName = 'Group';

export default Group;
