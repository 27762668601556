import React, {Suspense, forwardRef, useCallback, useState, useMemo} from 'react';
import clsx from 'clsx';

import {useEvergreenTranslations} from 'shared/ui/providers/translations';

import Theme from 'shared/ui/atoms/theme';
import Body from 'shared/ui/atoms/text/body';
import ButtonPrimary from 'shared/ui/atoms/button/primary';
import ButtonSecondary from 'shared/ui/atoms/button/secondary';
import ButtonTertiary from 'shared/ui/atoms/button/tertiary';

import sharedColor from '../../css/functions/color.scss';

import {CookieConsentProps, Translations} from './types';
import {Selections} from './components/settings/types';
import {DO_NOT_OPT_OUT, OPT_OUT} from './constants';
import {getOptOutCookieValue, setOptOutCookieValue, setCatPrefCookieValue} from './helpers';

import styles from './styles.scss';

const SettingsModal = React.lazy(() => import('./components/settings'));

const CookieConsent = forwardRef<HTMLDivElement, CookieConsentProps>(
  (
    {
      backdrop = true,
      cookieSettingsEnabled = true,
      className,
      color = sharedColor.grey600,
      companyName = 'Workable',
      onAccept,
      onAcceptDone,
      onSaveSettings,
      onSaveSettingsDone,
      onDecline,
      onDeclineDone,
      privacyPolicyUrl = 'https://www.workable.com/privacy',
      cookiesPolicyUrl = 'https://www.workable.com/cookies',
      texts: _texts,
      ...props
    },
    ref
  ) => {
    const translations = useEvergreenTranslations<Translations>('cookieConsent', _texts);

    const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);

    const theme = useMemo(() => ({'color-cookie-consent': color}), [color]);

    const [isVisible, setVisibility] = useState(getOptOutCookieValue() === '');

    const handleAccept = useCallback(() => {
      if (onAccept) {
        onAccept();
      } else {
        setOptOutCookieValue(DO_NOT_OPT_OUT);

        if (cookieSettingsEnabled) {
          setCatPrefCookieValue([true, true, true]);
        }
      }

      setVisibility(false);

      if (onAcceptDone) {
        onAcceptDone();
      }
    }, [onAccept, onAcceptDone, cookieSettingsEnabled]);

    const handleDecline = useCallback(() => {
      if (onDecline) {
        onDecline();
      } else {
        setOptOutCookieValue(OPT_OUT);

        if (cookieSettingsEnabled) {
          setCatPrefCookieValue([false, false, false]);
        }
      }

      setVisibility(false);

      if (onDeclineDone) {
        onDeclineDone();
      }
    }, [onDecline, onDeclineDone, cookieSettingsEnabled]);

    const handleSaveSettings = useCallback(
      (value: Selections) => {
        if (onSaveSettings) {
          onSaveSettings(value);
        } else {
          setOptOutCookieValue(DO_NOT_OPT_OUT);
          setCatPrefCookieValue(value);
        }

        setVisibility(false);

        if (onSaveSettingsDone) {
          onSaveSettingsDone(value);
        }
      },
      [onSaveSettings, onSaveSettingsDone, handleAccept]
    );

    const getCookieBannerTextWithSettings = useCallback(() => {
      return translations.textSettings({
        cookiesPolicy: (
          <a
            className={styles.link}
            data-ui="cookie-consent-cookies-policy"
            href={cookiesPolicyUrl}
            rel="noopener"
            target="_blank"
          >
            {translations.cookiesPolicy}
          </a>
        )
      });
    }, [translations, cookiesPolicyUrl]);

    const getCookieBannerText = useCallback(() => {
      return (
        <>
          <span>{translations.text({companyName})}</span>{' '}
          <a
            className={styles.link}
            data-ui="cookie-consent-privacy-policy"
            href={privacyPolicyUrl}
            rel="noopener"
            target="_blank"
          >
            {translations.privacyPolicy}
          </a>
        </>
      );
    }, [translations, companyName, privacyPolicyUrl]);

    if (!isVisible) {
      return null;
    }

    return (
      <Theme theme={theme}>
        {!!backdrop && <div className={styles.backdrop} data-ui="backdrop" />}

        <div
          ref={ref}
          className={clsx(styles.container, className, {
            [styles.settingsEnabled]: cookieSettingsEnabled
          })}
          data-ui="cookie-consent"
          {...props}
        >
          <Body secondary className={styles.text}>
            {cookieSettingsEnabled ? getCookieBannerTextWithSettings() : getCookieBannerText()}
          </Body>

          <div className={styles.buttonsContainer}>
            {cookieSettingsEnabled && (
              <ButtonTertiary
                onClick={() => setSettingsModalOpen(true)}
                className={styles.tertiaryButton}
                data-ui="cookie-consent-settings"
              >
                {translations.button.settings}
              </ButtonTertiary>
            )}

            <div className={styles.mainButtons}>
              <ButtonPrimary onClick={handleAccept} className={styles.primaryButton} data-ui="cookie-consent-accept">
                {cookieSettingsEnabled ? translations.button.acceptAll : translations.button.accept}
              </ButtonPrimary>

              <ButtonSecondary
                onClick={handleDecline}
                className={styles.secondaryButton}
                data-ui="cookie-consent-decline"
              >
                {cookieSettingsEnabled ? translations.button.declineAll : translations.button.decline}
              </ButtonSecondary>
            </div>
          </div>

          {cookieSettingsEnabled && (
            <Suspense fallback={null}>
              <SettingsModal
                theme={theme} // Modal is rendered in body, so it needs the theme to be passed down
                open={isSettingsModalOpen}
                texts={_texts}
                cookiesPolicyUrl={cookiesPolicyUrl}
                onClose={() => setSettingsModalOpen(false)}
                onAccept={handleAccept}
                onSaveSettings={handleSaveSettings}
              />
            </Suspense>
          )}
        </div>
      </Theme>
    );
  }
);

export default CookieConsent;
