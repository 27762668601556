import React, {useMemo} from 'react';

import {useEvergreenTranslations} from 'shared/ui/providers/translations';

import Theme, {ThemeProps} from 'shared/ui/atoms/theme';
import Modal, {Content, ModalProps} from 'shared/ui/organisms/dialog/modal';
import IconButton from 'shared/ui/molecules/button/icon';
import RemoveIcon from 'shared/ui/atoms/icon/remove';

import ButtonPrimary from 'shared/ui/atoms/button/primary';
import ButtonSecondary from 'shared/ui/atoms/button/secondary';

import CookieTabs, {CookieTabsProps} from './tabs';

import useSettings from './hooks/useSettings';

import {Selections} from './types';
import {Locales, Translations} from '../../types';

import styles from './styles.scss';

type SettingsModalProps = Omit<ModalProps, 'texts'> & {
  cookiesPolicyUrl?: string;
  theme: ThemeProps['theme'];
  texts?: Locales;
  onClose?: () => void;
  onAccept?: () => void;
  onSaveSettings?: (value: Selections) => void;
};

const SettingsModal = ({
  onClose,
  onAccept,
  onSaveSettings,
  texts: _texts,
  theme,
  cookiesPolicyUrl,
  ...props
}: SettingsModalProps) => {
  const {selections, updateSelection} = useSettings();

  const texts = useEvergreenTranslations<Translations>('cookieConsent', _texts);

  const cookieSections = useMemo<CookieTabsProps['tabs']>(
    () => [
      {
        id: 'about',
        index: -1,
        title: texts.sections.about.title,
        description: texts.sections.about.description({
          cookiesPolicy: (
            <a
              className={styles.link}
              data-ui="cookie-consent-cookies-policy"
              href={cookiesPolicyUrl}
              rel="noopener"
              target="_blank"
            >
              {texts.cookiesPolicy}
            </a>
          )
        })
      },
      {
        id: 'necessary',
        index: -1,
        preEnabled: true,
        title: texts.sections.necessary.title,
        description: texts.sections.necessary.description
      },
      {
        id: 'analytics',
        index: 0,
        title: texts.sections.analytics.title,
        description: texts.sections.analytics.description
      },
      {
        id: 'advertisement',
        index: 1,
        title: texts.sections.advertisement.title,
        description: texts.sections.advertisement.description
      },
      {
        id: 'functionality',
        index: 2,
        title: texts.sections.functionality.title,
        description: texts.sections.functionality.description
      }
    ],
    [texts, cookiesPolicyUrl]
  );

  return (
    <Modal
      {...props}
      dismissable={false}
      mobile={false}
      className={styles.settingsModal}
      size={780}
      onEscapePress={onClose}
    >
      <Content>
        <Theme theme={theme}>
          <div className={styles.contentContainer}>
            <div className={styles.title}>
              <IconButton aria-label={texts.close} transparent onClick={onClose}>
                <RemoveIcon />
              </IconButton>
            </div>

            <CookieTabs
              tabs={cookieSections}
              selections={selections}
              className={styles.contentSettings}
              texts={texts}
              onToggle={updateSelection}
            />

            <div className={styles.actions}>
              <ButtonSecondary
                className={styles.secondaryButton}
                data-ui="cookie-consent-save-settings"
                onClick={() => onSaveSettings?.(selections)}
              >
                {texts.button.saveSettings}
              </ButtonSecondary>

              <ButtonPrimary
                className={styles.primaryButton}
                data-ui="cookie-consent-accept-in-modal"
                onClick={onAccept}
              >
                {texts.button.acceptAll}
              </ButtonPrimary>
            </div>
          </div>
        </Theme>
      </Content>
    </Modal>
  );
};

export default SettingsModal;
