import React from 'react';
import BaseSvg, {setSymbol} from '../base';
import Graph from '../base/graph';

const Icon = ({className, graphClassName, transform, ...props}) => (
  <BaseSvg {...props} className={className} transform={transform}>
    <Graph fill {...props} className={graphClassName}>
      <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"/>
    </Graph>
  </BaseSvg>
);

Icon.displayName = 'Icon.CheckCircle';

setSymbol(Icon);

export default Icon;
