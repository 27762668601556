import React, {useMemo, useCallback} from 'react';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import getRandomString from 'shared/ui/helpers/getRandomString';
import Dialog from 'shared/ui/organisms/dialog/base';
import ActionableIcon from 'shared/ui/atoms/icon/actionable';
import RemoveIcon from 'shared/ui/atoms/icon/remove';
import Transition from 'shared/ui/behaviors/transition';
import {useEvergreenTranslations} from 'shared/ui/providers/translations';
import symbols from 'shared/ui/symbols';
import debounce from 'lodash/debounce';
import EvergreenResponsiveProvider from 'shared/ui/providers/responsive';
import withResponsiveness from './withResponsiveness';
import {TRANSITION_DURATION} from '../base/container';
import {DIALOG_ROLES} from '../constants';

import '../base/styles.scss';
// eslint-disable-next-line no-unused-vars
import styles from './styles.scss';
import modalVars from './variables.scss';

const lockBodyScroll = () => {
  const dataset = document.body.dataset;
  // remember initial overflow values
  dataset.initialBodyOverflowX = document.body.style.overflowX;
  dataset.initialBodyOverflowY = document.body.style.overflowY;
  // lock scroll
  document.body.style.overflowY = 'hidden';
  document.body.style.overflowX = 'hidden';
};

const restoreBodyScroll = () => {
  const dataset = document.body.dataset;
  // revert to initial overflow values
  document.body.style.overflowX = dataset.initialBodyOverflowX;
  document.body.style.overflowY = dataset.initialBodyOverflowY;
  // cleanup
  delete dataset.initialBodyOverflowX;
  delete dataset.initialBodyOverflowY;
};

const getRemainingModalCount = () => {
  const dataset = document.body.dataset;
  return dataset.evergreenModalCount ? parseInt(dataset.evergreenModalCount, 10) : 0;
};

const announceOpen = () => {
  document.body.dataset.evergreenModalCount = getRemainingModalCount() + 1;
};

const announceClose = () => {
  document.body.dataset.evergreenModalCount = Math.max(getRemainingModalCount() - 1, 0);
};

const getModalWidthStyles = requestedWidth => {
  const sizeStep = 100;
  const baseWidth = Number((modalVars['min-modal-width'] || '0px').replace('px', ''));
  const widthDiff = Number(requestedWidth) - baseWidth;
  const warnDeveloper = () => {
    if (process && process.env && process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(
        [
          `${requestedWidth} is not a valid width value. Falling back to closest valid value.`,
          `Valid values are from ${baseWidth} and upwards with step of ${sizeStep} px. e.g size="${
            baseWidth + sizeStep
          }"`
        ].join(' ')
      );
    }
  };

  if (!requestedWidth || widthDiff <= 0) {
    if (widthDiff < 0) {
      warnDeveloper();
    }

    return {
      width: `${baseWidth}px`
    };
  }

  const extraStepsWidth = Math.floor(widthDiff / sizeStep) * sizeStep;

  if (widthDiff % sizeStep > 0) {
    warnDeveloper();
  }

  return {
    width: `${baseWidth + extraStepsWidth}px`
  };
};

const isChildOfType = (child, symbolType) => {
  return React.isValidElement(child) && !!child.type[symbolType];
};

export const findChildrenOfType = (children, symbolType) => {
  const foundComponents = [];
  React.Children.forEach(children, child => {
    if (isChildOfType(child, symbolType)) {
      foundComponents.push(child);
    }
  });

  return foundComponents;
};

const DismissActionComponent = ({onClose, texts: _texts, mobile, DismissIcon, DismissButton}) => {
  const texts = useEvergreenTranslations('modal', _texts);

  const icon = useMemo(
    () => (DismissIcon ? DismissIcon : <RemoveIcon color={`${mobile ? 'grey500' : 'white'}`} transparent />),
    [mobile, DismissIcon]
  );

  const Button = useMemo(() => {
    return DismissButton && symbols.Button.includes(DismissButton) ? DismissButton : ActionableIcon;
  }, [DismissButton]);

  const handleEnterPressed = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      onClose(e);
    },
    [onClose]
  );

  return (
    <Dialog.Actions className={styles.dismissable} onClick={onClose} dismissIndex={0} data-dismiss-container>
      <Button
        aria-label={texts.dismiss}
        handleSpacePressed={onClose}
        handleEnterPressed={handleEnterPressed}
        data-ui="dismiss-button"
      >
        {icon}
      </Button>
    </Dialog.Actions>
  );
};

const HeaderRegionComponent = ({ugly, children}) => (
  <div className={clsx([{[styles['header-region']]: true}, {[styles.ugly]: ugly}])}>{children}</div>
);

const hasTitle = ({title, children}) => {
  const titleChildren = findChildrenOfType(children, symbols.Dialog.Title);

  return titleChildren.length || title;
};

const renderTitle = (
  {dismissable, onClose, texts, title, children, mobile, DismissIcon, DismissButton, hideDismissButton},
  arias
) => {
  let titleComponents;
  let empty;

  const titleChildren = findChildrenOfType(children, symbols.Dialog.Title);

  if (titleChildren.length) {
    titleComponents = titleChildren.map((foundChild, key) => {
      const {ugly, ...restProps} = foundChild.props;

      return (
        <HeaderRegionComponent key={key} ugly={ugly}>
          <foundChild.type {...restProps} mobile={mobile} />
          {!key && dismissable && (mobile || !hideDismissButton) ? (
            <DismissActionComponent
              onClose={onClose}
              texts={texts}
              mobile={mobile}
              DismissIcon={DismissIcon}
              DismissButton={DismissButton}
            />
          ) : null}
        </HeaderRegionComponent>
      );
    });
  }

  if (!titleComponents && (title || dismissable)) {
    empty = !title;
    titleComponents = (
      <HeaderRegionComponent ugly={!mobile}>
        <Dialog.Title mobile={mobile}>{title ? title : ' '}</Dialog.Title>
        {dismissable && (mobile || !hideDismissButton) ? (
          <DismissActionComponent
            onClose={onClose}
            texts={texts}
            mobile={mobile}
            DismissIcon={DismissIcon}
            DismissButton={DismissButton}
          />
        ) : null}
      </HeaderRegionComponent>
    );
  }

  return titleComponents ? (
    <div
      className={clsx(styles['modal-header'], {[styles.empty]: empty})}
      id={arias['aria-labelledby']}
      data-role={DIALOG_ROLES.Header}
    >
      {titleComponents}
    </div>
  ) : null;
};

const renderContent = ({content, children}, arias) => {
  let contentComponents;

  const foundChildren = findChildrenOfType(children, symbols.Dialog.Content);

  if (foundChildren.length) {
    contentComponents = React.Children.map(children, child => {
      if (isChildOfType(child, symbols.Dialog.Content)) {
        const {ugly, ...props} = child.props;
        return (
          <child.type
            key={child.key}
            ref={child.ref}
            {...props}
            className={clsx(styles['content-region'], {[styles.ugly]: ugly}, props.className)}
          />
        );
      }
    });
  }

  if (!contentComponents && content) {
    return (
      <Dialog.Content className={clsx(styles['modal-content'], styles['content-region'])}>{content}</Dialog.Content>
    );
  }

  return contentComponents ? (
    <div className={styles['modal-content']} id={arias['aria-describedby']} data-role={DIALOG_ROLES.ContentContainer}>
      {contentComponents}
    </div>
  ) : null;
};

const renderActions = ({actions, children, onClose}) => {
  let actionsComponent;

  const [firstFoundChild] = findChildrenOfType(children, symbols.Dialog.Actions);

  if (firstFoundChild) {
    actionsComponent = (
      <firstFoundChild.type
        {...firstFoundChild.props}
        className={clsx(styles['modal-actions'], firstFoundChild.props?.className)}
        data-form-actions
        onClose={onClose}
      />
    );
  }

  if (!actionsComponent && actions && actions.type === Dialog.Actions) {
    return (
      <actions.type
        {...actions.props}
        className={clsx(styles['modal-actions'], actions.props?.className)}
        data-form-actions
        onClose={onClose}
      />
    );
  }

  return actionsComponent;
};

class Modal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hideHeader: false
    };

    this.defaultInstanceId = getRandomString();
  }

  componentDidMount() {
    // mounted open
    if (this.props.open) {
      this.prepareForOpen();
    }
  }

  componentDidUpdate(prevProps) {
    // just opened
    if (!prevProps.open && this.props.open) {
      this.prepareForOpen();
    }
    // just closed
    if (prevProps.open && !this.props.open) {
      this.prepareForClose();
    }
  }

  componentWillUnmount() {
    // unmounted while open
    if (this.props.open) {
      this.prepareForClose();
    }
  }

  prepareForOpen() {
    if (!this.props.backdrop) {
      return;
    }
    // the first modal to open
    if (getRemainingModalCount() === 0) {
      lockBodyScroll();
    }
    announceOpen();
  }

  prepareForClose() {
    if (!this.props.backdrop) {
      return;
    }
    announceClose();
    // the last modal to close
    if (getRemainingModalCount() === 0) {
      restoreBodyScroll();
    }
  }

  handleEscapePress = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const {onClose} = this.props;
    if (onClose) {
      onClose(event);
    }
  };

  setScrollOffsets = debounce(({target}) => {
    if (target instanceof HTMLElement === false || !target.closest) {
      return;
    }
    const {offsetHeight = 0, scrollHeight = 0, scrollTop = 0} = target;

    const dialogContainerEl = target.closest(`[data-role="${DIALOG_ROLES.Container}"]`);
    if (dialogContainerEl) {
      dialogContainerEl.setAttribute('data-scroll-top-offset', scrollTop);
      dialogContainerEl.setAttribute('data-scroll-bottom-offset', scrollHeight - (offsetHeight + scrollTop));
    }
  });

  scrollDistance = undefined;
  scrollDirection = undefined;

  updateScrollState = debounce(({target}) => {
    // ignore propagated scroll events from children
    if (target.getAttribute('data-role') !== DIALOG_ROLES.Container) {
      return;
    }

    const {scrollTop} = target || {};

    this.scrollDistance = scrollTop;
    this.scrollDirection = this.scrollDistance <= (scrollTop || 0) ? 'down' : 'up';

    const hideHeader =
      !this.props.isHeaderAlwaysVisible && this.scrollDirection === 'down' && this.scrollDistance >= 120;

    this.setState({hideHeader});
  });

  handleScroll = ({nativeEvent}) => {
    this.setScrollOffsets(nativeEvent);
    this.updateScrollState(nativeEvent);
    if (typeof this.props.onScroll === 'function') {
      this.props.onScroll(nativeEvent);
    }
  };

  /**
   *
   * @param {HTMLElement} containerElement
   */
  handleDialogOpen = dialogElement => {
    const {onTransitionEnterStarted} = this.props;

    if (typeof onTransitionEnterStarted === 'function') {
      onTransitionEnterStarted(dialogElement);
    }

    if (!(dialogElement instanceof Element)) {
      return;
    }

    const containerElement = dialogElement.parentElement;
    if (!(containerElement instanceof Element)) {
      return;
    }

    const {zIndex: containerZIndex} = getComputedStyle(containerElement);

    // z-index has been explicitly defined to container - it should not be overridden
    if (Number(containerZIndex) > 0) {
      return;
    }

    const {zIndex: dialogZIndex} = window.getComputedStyle(dialogElement);

    containerElement.style.setProperty('z-index', dialogZIndex, 'important');

    const backdropElement = containerElement.querySelector('[data-evergreen-dialog-backdrop="true"]');
    if (backdropElement) {
      backdropElement.style.setProperty('z-index', parseInt(dialogZIndex || '131', 10) - 1, 'important');
    }
  };

  render() {
    /* eslint-disable no-unused-vars */
    const {
      texts,
      id = this.defaultInstanceId,
      dismissable,
      hideDismissButton,
      sticky,
      content,
      title,
      onClose,
      mobile,
      open,
      size,
      isHeaderAlwaysVisible = false,
      onClickOutside = onClose,
      containerElement,
      as,
      wrapperClassName,
      ...props
    } = this.props;

    const {ROLE} = Dialog.Container.constants;

    const ariaProps = {
      'aria-atomic': true,
      'aria-describedby': content ? `${id}_modal_body` : '',
      'aria-labelledby': hasTitle(this.props) ? `${id}_modal_title` : '',
      'aria-modal': true,
      role: ROLE.DIALOG
    };

    const dismissableProps = dismissable
      ? {
          onClickOutside,
          onEscapePress: this.handleEscapePress
        }
      : {};

    return ReactDOM.createPortal(
      <Transition duration={TRANSITION_DURATION} mounted={open}>
        <div className={clsx(styles['modal-wrapper'], wrapperClassName)} data-role={DIALOG_ROLES.Wrapper}>
          <Dialog.Container
            {...ariaProps}
            {...props}
            className={clsx(
              [
                {
                  [styles.modal]: true,
                  [styles.mobile]: mobile,
                  [styles.sticky]: sticky,
                  [styles.backdrop]: props.backdrop,
                  [styles['hide-header']]: this.state.hideHeader,
                  [styles['no-dismiss-button']]: !dismissable || hideDismissButton
                }
              ],
              props.className
            )}
            {...dismissableProps}
            id={id}
            open={open}
            onDismiss={onClose}
            onScroll={this.handleScroll}
            style={{...props.style, ...getModalWidthStyles(size)}}
            onTransitionEnterStarted={this.handleDialogOpen}
            as={props.onSubmit ? 'form' : as}
          >
            {renderTitle(this.props, ariaProps)}
            {renderContent(this.props, ariaProps)}
            {renderActions(this.props)}
          </Dialog.Container>
        </div>
      </Transition>,
      containerElement
    );
  }
}

const ResponsiveModal = withResponsiveness(Modal);

const WrappedResponsiveModal = props => {
  return (
    <EvergreenResponsiveProvider>
      <ResponsiveModal {...props} />
    </EvergreenResponsiveProvider>
  );
};

WrappedResponsiveModal.defaultProps = {
  open: true,
  dismissable: true,
  backdrop: true,
  onClose: () => {},
  containerElement: document.body
};

WrappedResponsiveModal.displayName = 'Dialog.Modal';

WrappedResponsiveModal.constants = Dialog.Container.constants;

WrappedResponsiveModal.propTypes = {
  ...Dialog.Container.propTypes,
  /** Sets the header title for the modal, unless a Modal.Title passed as child */
  title: PropTypes.string,
  /** Sets the content of the modal, unless a Modal.Content passed as child */
  content: PropTypes.string,
  /** Enables dismiss on modal. Also enables on click outside */
  dismissable: PropTypes.bool,
  /** Hides dismiss button on modal. */
  hideDismissButton: PropTypes.bool,
  /** Callback used when the modal should be closed */
  onClose: PropTypes.func,
  /** Callback used when the modal scrolls */
  onScroll: PropTypes.func,
  /** Translation texts for modal */
  texts: PropTypes.object,
  /** When set to true, enables mobile view for modal */
  mobile: PropTypes.bool,
  /** The size of the modal, can be any value higher than 480 with 100px step. e.g. 480/580/680 */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The Dismiss Icon of the modal. By Default RemoveIcon is used. */
  DismissIcon: PropTypes.element
};

export default WrappedResponsiveModal;
