import React from 'react';
import symbols from 'shared/ui/symbols';
import TextBody from 'shared/ui/atoms/text/body';
import {DIALOG_ROLES} from '../constants';

export type DialogContentProps = React.HTMLAttributes<HTMLDivElement> & {
  ugly?: boolean;
};

const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps>(({children, ugly, ...props}, ref) => {
  return (
    <div {...props} ref={ref} data-role={DIALOG_ROLES.Content}>
      {typeof children === 'string' ? <TextBody primary>{children}</TextBody> : children}
    </div>
  );
});

DialogContent.displayName = 'Dialog.Content';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
DialogContent[symbols.Dialog.Content] = true;

export default DialogContent;
