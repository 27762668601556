import React from 'react';
import BaseSvg, {setSymbol} from '../base';
import Graph from '../base/graph';

const Icon = ({className, graphClassName, transform, ...props}) => (
  <BaseSvg {...props} className={className} transform={transform}>
    <Graph fill {...props} className={graphClassName}>
      <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"/>
    </Graph>
  </BaseSvg>
);

Icon.displayName = 'Icon.RemoveCircle';

setSymbol(Icon);

export default Icon;
