import {useState, useCallback} from 'react';

/**
 * @example
 * import 'shared/hooks/useToggle';
 *
 * const [value, toggle, toggleOn, toggleOff]  = useToggle(false);
 *
 * @param {Boolean} initialValue
 */

export default initialValue => {
  const [value, setToggle] = useState(!!initialValue);
  const toggle = useCallback(() => setToggle(oldValue => !oldValue), []);
  const toggleOn = useCallback(() => setToggle(true), []);
  const toggleOff = useCallback(() => setToggle(false), []);

  return [value, toggle, toggleOn, toggleOff];
};
