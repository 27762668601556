export const getMetaVariable = name => {
  const metaField = window.document?.head?.querySelector(`meta[name="${name}"]`);

  const content = metaField && metaField.content;

  try {
    return JSON.parse(content);
  } catch (e) {
    return content;
  }
};

export const getMeta = () =>
  Object.freeze(
    Object.assign(Object.create(null), {
      subdomain: getMetaVariable('subdomain'),
      ga4: getMetaVariable('ga4'),
      themeColor: getMetaVariable('theme-color'),
      hasCustomDomain: getMetaVariable('hasCustomDomain'),
      domain: getMetaVariable('domain'),
      job: getMetaVariable('job'),
      version: getMetaVariable('version')
    })
  );

export default getMeta();
