import React from 'react';
import {updateConsentFromCookie} from 'shared/helpers/analytics/gtmConsent';
import SharedCookieConsent from 'shared/ui/organisms/cookieConsent';
import {meta} from 'candidate/config';
import {COOKIE_CONSENT_BANNER_ATTR} from './constants';
import {useIsCookieConsentEnabled} from './useIsCookieConsentEnabled';

// Sets the attribute here so when/if the related GTM trigger fires
// before the component renders, it can see that a cookie consent
// banner will be added.
document.body.setAttribute(COOKIE_CONSENT_BANNER_ATTR, 'true');

const CookieConsent = () => {
  const isCookieConsentEnabled = useIsCookieConsentEnabled();

  if (!isCookieConsentEnabled) {
    return null;
  }

  const color = meta.themeColor ?? undefined;

  return (
    <SharedCookieConsent
      color={color}
      cookieSettingsEnabled
      onAcceptDone={updateConsentFromCookie}
      onDeclineDone={updateConsentFromCookie}
      onSaveSettingsDone={updateConsentFromCookie}
    />
  );
};

export default CookieConsent;
