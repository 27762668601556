export const getURLInstance = (url = window?.location) => {
  if (typeof url === 'string') {
    return new URL(url);
  }

  if (url instanceof URL || url instanceof Location) {
    return url;
  }
};

export const getSiteVersion = () => {
  const fallbackVersion = 'v0.0.0';

  if (typeof __VERSION_OR_COMMIT_HASH__ !== 'undefined') {
    // eslint-disable-next-line no-undef
    return __VERSION_OR_COMMIT_HASH__;
  }

  try {
    return document.querySelector('meta[name="version"]').content;
  } catch (e) {
    // skip, default version resolution not supported. App should handle it
  }

  return fallbackVersion;
};

export const canEnvironmentRunQAPerformance = environment => environment === 'workablestg10';
