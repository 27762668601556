import getContainingBlockElement from './getContainingBlockElement';
import getContainingBlockHeight from './getContainingBlockHeight';
import getContainingBlockWidth from './getContainingBlockWidth';
import getDocumentHeight from './getDocumentHeight';
import getAbsoluteSize from './getAbsoluteSize';
import getBoundingBox from './getBoundingBox';

import {MOBILE_MARGIN, POSITIONS} from '../constants';

/**
 * Calculates a styles object for the dialog positioning taking into consideration
 * the given arguments.
 *
 * @param {{
 *  element: HTMLElement,
 *  target: HTMLElement,
 *  positionVertical: 'TOP' | 'BOTTOM' | 'VERTICAL_CENTER',
 *  positionHorizontal: 'LEFT' | 'RIGHT' | 'HORIZONTAL_CENTER',
 *  fit: boolean
 * }} props
 *
 * @returns {CSSStyleDeclaration}
 */
const getDropdownPositionScale = ({target, element, positionVertical, positionHorizontal, fit, stick}) => {
  const {
    bottom: targetBottom,
    top: targetTop,
    left: targetLeft,
    right: targetRight,
    width: targetWidth,
    height: targetHeight
  } = getBoundingBox(target, {container: getContainingBlockElement(element)});

  const [dropdownHeight, dropdownWidth] = getAbsoluteSize(element);

  const fitsToBottom = targetBottom + (dropdownHeight - targetHeight) / 2 < getDocumentHeight();
  const fitsToTop = targetTop - (dropdownHeight - targetHeight) / 2 >= 0;

  let top = 'unset';
  let bottom = 'unset';
  let left = 'unset';
  let right = 'unset';

  if (positionVertical === POSITIONS.TOP) {
    const goesBeyondTop = targetTop - dropdownHeight < MOBILE_MARGIN;

    if (stick && goesBeyondTop) {
      top = MOBILE_MARGIN;
      bottom = 'unset';
    } else {
      top = 'unset';
      bottom = getContainingBlockHeight(element) - targetTop;
    }
  } else if (positionVertical === POSITIONS.BOTTOM) {
    const goesBeyondBottom = targetBottom + dropdownHeight >= getDocumentHeight() - MOBILE_MARGIN;

    if (stick && goesBeyondBottom) {
      top = 'unset';
      bottom = MOBILE_MARGIN;
    } else {
      top = targetBottom;
      bottom = 'unset';
    }
  } else if (positionVertical === POSITIONS.VERTICAL_CENTER) {
    const goesBeyondTop = targetTop - dropdownHeight / 2 < MOBILE_MARGIN;
    const goesBeyondBottom = targetBottom + dropdownHeight / 2 >= getDocumentHeight() - MOBILE_MARGIN;

    if (stick && goesBeyondTop && !goesBeyondBottom) {
      top = MOBILE_MARGIN;
      bottom = 'unset';
    } else if (stick && !goesBeyondTop && goesBeyondBottom) {
      top = 'unset';
      bottom = MOBILE_MARGIN;
    } else if (!fitsToTop && fitsToBottom) {
      top = targetTop;
      bottom = 'unset';
    } else if (fitsToTop && !fitsToBottom) {
      top = 'unset';
      bottom = getContainingBlockHeight(element) - targetBottom;
    } else {
      top = targetTop - (dropdownHeight - targetHeight) / 2;
      bottom = 'unset';
    }
  }

  if (positionHorizontal === POSITIONS.LEFT) {
    if (positionVertical === POSITIONS.VERTICAL_CENTER) {
      right = getContainingBlockWidth(element) - targetLeft;
      left = 'unset';
    } else {
      right = 'unset';
      left = targetLeft;
    }
  } else if (positionHorizontal === POSITIONS.RIGHT) {
    if (positionVertical === POSITIONS.VERTICAL_CENTER) {
      right = 'unset';
      left = targetRight;
    } else {
      right = getContainingBlockWidth(element) - targetRight;
      left = 'unset';
    }
  } else if (positionHorizontal === POSITIONS.HORIZONTAL_CENTER) {
    const leftPosition = targetLeft - (dropdownWidth - targetWidth) / 2;

    const goesBeyondRight = leftPosition + dropdownWidth >= getContainingBlockWidth(element) - MOBILE_MARGIN;

    if (stick && goesBeyondRight) {
      right = MOBILE_MARGIN;
      left = 'unset';
    } else if (stick && leftPosition < MOBILE_MARGIN) {
      left = MOBILE_MARGIN;
      right = 'unset';
    } else {
      left = leftPosition <= MOBILE_MARGIN ? MOBILE_MARGIN : leftPosition;
      right = 'unset';
    }
  }

  const position = {
    top,
    bottom,
    left,
    right
  };

  if (fit) {
    return {...position, minWidth: targetWidth, maxWidth: targetWidth};
  }

  return position;
};

export default getDropdownPositionScale;
