import _gtm, {datadog, ga4 as _ga4} from 'shared/helpers/analytics';
import {isInEdit, isInPreview} from 'candidate/advanced/providers/mode';
import configuration, {meta} from 'candidate/config';
import {allowsCookieConsent} from 'candidate/shared/cookieConsent/helpers';
import {watchForConsent} from './helpers';

export const transformPageviewPath = (path, options) => {
  const {hasCustomDomain, subdomain = '_'} = options;
  if (!hasCustomDomain) {
    return path;
  }

  return path.replace(/^\/_\//, `/${subdomain}/`);
};

const startDataDog = ({datadog: datadogConfig, features} = {}) => {
  if (features && features.datadogEnabled && datadogConfig) {
    return datadog({
      ...datadogConfig,
      service: 'careers-page',
      sessionSampleRate: 0.5,
      version: meta.version
    });
  }
};

export const wrapper = ({dd, ga: {ga4Client} = {}, gtm} = {}) => ({
  event: (category, action, label, nonInteractive = false) => ({
    send: () => {
      if (gtm) {
        gtm.event(category, action, label, nonInteractive).send();
      }

      if (ga4Client) {
        ga4Client.event(category, action, label, nonInteractive).send();
      }
    }
  }),
  heap: () => ({
    addEventProperties: properties => {
      const heap = window.heap;
      if (heap) {
        heap.addEventProperties(properties);
      }
    },
    clearAndAddEventProperties: properties => {
      const heap = window.heap;
      if (heap) {
        heap.clearEventProperties();
        heap.addEventProperties(properties);
      }
    },
    removeEventProperty: key => {
      const heap = window.heap;
      if (heap) {
        heap.removeEventProperty(key);
      }
    },
    sendCustomEvent: (event, properties) => {
      const heap = window.heap;
      if (heap) {
        heap.track(event, properties);
      }
    }
  }),
  pageview: path => ({
    send: () => {
      if (gtm) {
        gtm.pageview(transformPageviewPath(path, meta)).send();
      }

      if (ga4Client) {
        ga4Client.pageview(path).send();
      }
    }
  }),
  variable: (key, value) => ({
    send: () => {
      if (dd) {
        dd.context(key, value).add();
      }

      if (gtm) {
        gtm.variable(key, value).send();
      }
    }
  })
});

const analyticsEnabled = () =>
  !isInEdit(window?.location?.pathname, window?.location?.hostname) &&
  !isInPreview(window?.location?.pathname) &&
  allowsCookieConsent();

export const _default = ({ga4Id} = {}) =>
  configuration.then(({gtm: config = {}, ...restConfig}) => {
    if (!analyticsEnabled()) {
      return wrapper();
    }

    return watchForConsent().then(({areAnalyticsCookiesEnabled}) => {
      if (!areAnalyticsCookiesEnabled) {
        return wrapper();
      }

      const gtm = _gtm({
        gtm_auth: config.auth,
        gtm_consent_mode_enabled: true,
        gtm_preview: config.env,
        id: config.id
      });

      const ga4Client = ga4Id
        ? _ga4(ga4Id, {
            dataLayerName: 'dataLayer4',
            linker: {
              accept_incoming: true
            },
            send_page_view: false
          })
        : undefined;

      const dd = startDataDog(restConfig);

      return wrapper({dd, ga: {ga4Client}, gtm});
    });
  });

export default _default({ga4Id: meta.ga4});
