import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'shared/ui/atoms/input/toggle';
import Control from 'shared/ui/molecules/control/text';
import withMemo from 'shared/ui/helpers/hocs/withMemo';
import getRandomString from 'shared/ui/helpers/getRandomString';

const ToggleControl = ({
  id = getRandomString(),
  label,
  helper,
  error,
  optional,
  tooltip,
  required,
  style,
  labelWrap = true,
  className,
  ...props
}) => (
  <Control
    id={id}
    label={label}
    helper={helper}
    error={error}
    optional={optional}
    tooltip={tooltip}
    className={className}
    required={required}
    labelWrap={labelWrap}
    style={style}
  >
    <Toggle {...props} labelWrap={false} toggleOnClick={!labelWrap} id={id} required={required} error={error} />
  </Control>
);

const MemoToggleControl = withMemo(ToggleControl);

MemoToggleControl.displayName = 'Control.Toggle';

MemoToggleControl.propTypes = {
  ...Control.propTypes,
  ...Toggle.propTypes,
  /** The text or React component which will be rendered as the label of the toggle input. */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Controls if the label prop along with the input will be wrapped with an html `label` element. By default: `true`. */
  labelWrap: PropTypes.bool
};

export default MemoToggleControl;
