import React, {useCallback, useContext, useMemo} from 'react';
import ExternalSvg from 'assets/svg/external12.svg?sprite';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import qs from 'query-string';
import {useTranslation} from 'react-i18next';
import {matchPath} from 'react-router';
import {updateConsentFromCookie} from 'shared/helpers/analytics/gtmConsent';
import useToggle from 'shared/hooks/useToggle';
import ButtonTertiary from 'shared/ui/atoms/button/tertiary';
import SVGSprite from 'shared/ui/atoms/icon/SVGLoaders/sprite';
import CookieConsentSettings from 'shared/ui/organisms/cookieConsent/components/settings';
import {DO_NOT_OPT_OUT, OPT_OUT} from 'shared/ui/organisms/cookieConsent/constants';
import {setCatPrefCookieValue, setOptOutCookieValue} from 'shared/ui/organisms/cookieConsent/helpers';
import {getCookieCategoriesPreferences} from 'candidate/analytics/helpers';
import {meta} from 'candidate/config';
import i18n, {I18n} from 'candidate/i18n';
import AccountDetails from 'candidate/providers/account/details';
import Job from 'candidate/providers/job';
import {useIsCookieConsentEnabled} from 'candidate/shared/cookieConsent/useIsCookieConsentEnabled';
import parseURL from 'candidate/shared/parseurl';
import paths, {FILTERS} from 'candidate/shared/paths';
import styles from './styles.scss';

const ExternalIcon = () => <SVGSprite fill size={12} svg={ExternalSvg} transparent />;

export const Notice = ({dataUi, message, url}) => {
  if (!url) {
    return null;
  }

  const lng = i18n.language;
  let finalUrl;

  if (lng) {
    const parsed = parseURL(url);
    finalUrl = `${parsed.origin}${parsed.pathname}?${qs.stringify({...(parsed.querystring || {}), lng})}${parsed.hash}`;
  } else {
    finalUrl = url;
  }

  return (
    <I18n className={styles.compliance} data-ui={dataUi} Node="span" url={finalUrl}>
      {message}
    </I18n>
  );
};

Notice.propTypes = {
  dataUi: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  url: PropTypes.string
};

export const Compliance = ({account, ccpa, gdpr}) => {
  const hasGdrp = !isEmpty(gdpr) && gdpr.url;
  const hasCcpa = !isEmpty(ccpa) && ccpa.url;

  if (!hasCcpa && !hasGdrp) {
    return null;
  }

  return (
    <div>
      <I18n account={account} className={styles.compliance} data-ui="footer-compliance-notice" Node="span">
        footer.complianceNotice
      </I18n>
      {hasGdrp ? <Notice {...(gdpr || {})} dataUi="footer-gdpr" message="footer.gdpr" /> : null}
      {hasCcpa ? <Notice {...(ccpa || {})} dataUi="footer-ccpa" message="footer.ccpa" /> : null}
    </div>
  );
};

Compliance.propTypes = {
  account: PropTypes.string.isRequired,
  ccpa: PropTypes.shape({
    url: PropTypes.string
  }),
  gdpr: PropTypes.shape({
    url: PropTypes.string
  })
};

export const Eeoc = ({account, eeoc}) =>
  eeoc ? <div data-ui="footer-eeoc">{eeoc.disclaimer || i18n.t('footer.eeoc', {account})}</div> : null;

Eeoc.propTypes = {
  account: PropTypes.string.isRequired,
  eeoc: PropTypes.shape({
    disclaimer: PropTypes.string
  })
};

export const Disclaimers = ({account}) => {
  if (!account) {
    return null;
  }

  const {ccpa, eeoc, gdpr} = account.details || {};

  const hasGdrp = !isEmpty(gdpr) && gdpr.url;
  const hasCcpa = !isEmpty(ccpa) && ccpa.url;

  if (!hasCcpa && !hasGdrp && !eeoc) {
    return null;
  }

  return (
    <div className={styles.disclaimers}>
      <Compliance account={account.name} ccpa={ccpa} gdpr={gdpr} />
      <Eeoc account={account.name} eeoc={eeoc} />
    </div>
  );
};

Disclaimers.propTypes = {
  account: PropTypes.object.isRequired
};

export const Shimmer = () => (
  <div className={styles.footer}>
    <div className={styles.links}>
      <a className={styles.shimmerPoweredBy} />
    </div>
  </div>
);

export const PoweredBy = () => {
  const utmParams = '?utm_campaign=careers_page&utm_content=careers_page&utm_medium=feature&utm_source=careers_page';
  const isCookieConsentEnabled = useIsCookieConsentEnabled();
  const [isOpen, toggle] = useToggle();

  const handleSaveCookieSettings = useCallback(
    value => {
      setCatPrefCookieValue(value);
      updateConsentFromCookie();
      const {areAnalyticsCookiesEnabled} = getCookieCategoriesPreferences();
      setOptOutCookieValue(areAnalyticsCookiesEnabled ? DO_NOT_OPT_OUT : OPT_OUT);
      toggle();
    },
    [toggle]
  );

  const handleAcceptAll = useCallback(() => {
    setCatPrefCookieValue([true, true, true]);
    setOptOutCookieValue(DO_NOT_OPT_OUT);
    updateConsentFromCookie();
    toggle();
  }, [toggle]);

  const theme = useMemo(
    () => ({
      'color-cookie-consent': meta.themeColor
    }),
    []
  );

  return (
    <div className={styles.poweredBy} data-ui="footer-powered-by">
      <span className={styles.link}>
        <a
          data-ui="accessibility"
          href="http://workable.com/web-accessibility-statement"
          rel="noreferrer"
          target="_blank"
        >
          {i18n.t('footer.accessibility')}
          <ExternalIcon />
        </a>
      </span>
      {isCookieConsentEnabled && (
        <span className={styles.link}>
          <ButtonTertiary data-ui="update-cookie-preferences" onClick={toggle}>
            {i18n.t('footer.cookieSettings')}
          </ButtonTertiary>
          <CookieConsentSettings
            key={isOpen}
            onAccept={handleAcceptAll}
            onClose={toggle}
            onSaveSettings={handleSaveCookieSettings}
            open={isOpen}
            theme={theme}
          />
        </span>
      )}
      <span className={styles.link}>
        {i18n.t('footer.poweredBy')}
        <a href={`https://jobs.workable.com/${utmParams}`} rel="noreferrer" target="_blank">
          Workable
        </a>
      </span>
    </div>
  );
};

export const Footer = ({className, path = window.location.pathname}) => {
  const {
    i18n: {language}
  } = useTranslation(undefined, {useSuspense: false});

  const {accountDetails: account} = useContext(AccountDetails);
  const {job} = useContext(Job);

  if (matchPath(path, {path: FILTERS})) {
    return null;
  }

  if (!account) {
    return <Shimmer />;
  }

  return (
    <footer className={clsx(styles.footer, className)} data-id="footer">
      <Disclaimers account={account} />
      <div className={styles.links}>
        {account.url && (
          <div className={styles.wrapper}>
            <a data-ui="company-url" href={account.url} rel="noreferrer" target="_blank">
              {i18n.t('footer.viewWebsite')}
            </a>
          </div>
        )}
        <div className={styles.allJobsHelp}>
          {job && (
            <a data-ui="view-jobs-url" href={paths.root()}>
              {i18n.t('footer.viewAllJobs')}
            </a>
          )}
          <a
            data-ui="help-url"
            href={`https://jobseekers.workable.com/hc/${language}`}
            rel="noreferrer"
            target="_blank"
          >
            {i18n.t('footer.help')}
            <ExternalIcon />
          </a>
        </div>
      </div>
      <PoweredBy />
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string
};

export default Footer;
