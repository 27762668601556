import compact from 'lodash/compact';
import meta from 'candidate/config/meta';

let env = 'development';

const domain = (h = window && window.location && window.location.host, m = meta.domain) => {
  if (m) {
    return m;
  }

  const d = (h && h.split(':')[0]) || '';
  const host = d === 'localhost' ? [undefined, 'localhost'] : h.split('.');
  const [, _domain, ext = ''] = host;
  return compact([_domain, ext]).join('.');
};

export const getEnvFromHost = host => {
  switch (domain(host)) {
    case 'workable.com':
      env = 'production';
      break;
    case 'localhost':
    case 'workable.dev':
      env = 'development';
      break;
    case 'officedroid.com':
      env = 'officedroid';
      break;
    case 'workablestg3.com':
      env = 'stg3';
      break;
    case 'workablestg5.com':
      env = 'stg5';
      break;
    case 'workablestg10.com':
      env = 'stg10';
      break;
    case 'workabledemostg.com':
      env = 'demostg';
      break;
    case 'workabledemo.com':
      env = 'demo';
      break;
    default:
      env = __ENV__ || host.replace('workable', '').replace('.com', '');
  }

  return env;
};

export const isTestEnv = (_domain = window.location.hostname) => /workablestg\d+/.test(_domain);
