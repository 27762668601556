import useConfig from 'candidate/shared/hooks/useConfig';
import {isTestEnv} from 'candidate/shared/utils/env';
import {allowsCookieConsent} from './helpers';

export const useIsCookieConsentEnabled = () => {
  const isBlackListedEnv = isTestEnv();
  const config = useConfig();

  if (isBlackListedEnv) {
    return false;
  }

  const isGtmConfigAvailable = config?.gtm && ['auth', 'env', 'id'].every(key => Boolean(config.gtm[key]));

  if (!isGtmConfigAvailable) {
    return false;
  }

  return allowsCookieConsent();
};
